import { Button, Typography, Box, IconButton } from "@mui/material";
import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { useTranslation } from "react-i18next";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import Counter from "../../components/Counter/Counter";

const Home = ({ lang }) => {
  const styles = {
    fontFamily: lang === "ar" ? "myFont" : "",
    direction: lang === "ar" ? "rtl" : "ltr",
  };
  const [t] = useTranslation();
  const dynamicSpacing = lang === "ar" ? 1 : 15;
  const TextDynamicSpacing = lang === "ar" ? 0 : 2;
  return (
    <div>
      {/* First Section. ends before success counter */}
      <div className="home-container">
        <div
          id="firstSection"
          className={`first-section ${lang === "ar" ? "ar-lang-reset" : ""}`}
        >
          <div
            className={`first-content ${
              lang === "ar" ? "ar-lang-reset " : "en-lang-ml-xx"
            }`}
          >
            {/* <Typography>{t('title')}</Typography> */}
            <Typography
              textAlign={"center"}
              variant="h3"
              sx={{
                fontFamily: lang === "ar" ? "myFont" : "",
                direction: lang === "ar" ? "rtl" : "ltr",
              }}
              className="header"
            >
              {t("homeMainHeader")}
            </Typography>

            <Typography
              sx={{
                fontFamily: lang === "ar" ? "myFont" : "",
                direction: lang === "ar" ? "rtl" : "ltr",
              }}
              letterSpacing={TextDynamicSpacing}
              textAlign="center"
              marginTop={4}
              variant="h6 "
              fontWeight="200"
            >
              {t("homeMainText")}
            </Typography>

            <Button
              style={{
                fontFamily: lang === "ar" ? "myFont" : "",
                direction: lang === "ar" ? "rtl" : "ltr",
              }}
              component={Link}
              to={"/about"}
              sx={{
                marginTop: "3rem",
                textAlign: "center",
                backgroundColor: "#5595D0",
                borderRadius: "1rem",
                boxShadow: "none",
              }}
              variant="contained"
            >
              {t("learnMoreText")}
            </Button>
          </div>
        </div>

        {/* about, services and contact sections  */}
        <Link style={{ textDecoration: "none" }} to="/about">
          <Box
            className={`${
              lang === "ar" ? " about-section ar-lang-reset" : " about-section"
            }`}
          >
            <div
              className={` about-content ${
                lang === "ar" ? "ar-lang-reset ar-lang-mr-xx" : "en-lang-ml-xx"
              }`}
            >
              <Typography
                style={styles}
                variant="h2"
                letterSpacing={dynamicSpacing}
                fontSize={50}
                fontWeight={600}
                sx={{ color: "white" }}
              >
                {t("aboutTitle")}
              </Typography>
              {/* <Typography  lineHeight={1.8} variant='body2' letterSpacing={2} sx={{color:'white',}}>{t('aboutText')} </Typography> */}
            </div>
          </Box>
        </Link>

        <Link style={{ textDecoration: "none" }} to="/services">
          <Box
            className={`services-section ${
              lang === "ar" ? "ar-lang-reset" : ""
            }`}
          >
            <div
              className={`services-content ${
                lang === "ar" ? "ar-lang-reset ar-lang-mr-xx" : "en-lang-ml-xx"
              }`}
            >
              <Typography
                style={styles}
                variant="h2"
                letterSpacing={dynamicSpacing}
                fontSize={50}
                fontWeight={600}
                sx={{ color: "white" }}
              >
                {t("servicesTitle")}
              </Typography>
              {/* <Typography lineHeight={1.8} variant='body2' letterSpacing={2} sx={{color:'white',}}>{t('servicesText')} </Typography> */}
            </div>
          </Box>
        </Link>

        <Link style={{ textDecoration: "none" }} to="/contact">
          <Box
            className={`contact-section ${
              lang === "ar" ? "ar-lang-reset" : ""
            }`}
          >
            <div
              className={`contact-content ${
                lang === "ar" ? "ar-lang-reset ar-lang-mr-xx" : "en-lang-ml-xx"
              }`}
            >
              <Typography
                style={styles}
                variant="h2"
                letterSpacing={dynamicSpacing}
                fontSize={50}
                fontWeight={600}
                sx={{ color: "white" }}
              >
                {t("contactTitle")}
              </Typography>
              {/* <Typography lineHeight={1.8} variant='body2' letterSpacing={2} sx={{color:'white',}}>{t('contactText')}  </Typography> */}
            </div>
          </Box>
        </Link>
      </div>
      {/* end of first section  */}

      {/* success counter */}
      <div className="success-counter">
        <Typography
          fontSize={40}
          fontWeight={600}
          letterSpacing={dynamicSpacing}
          sx={{ color: "#5595D0" }}
          variant="h4"
        >
          {t("Our Success")}
        </Typography>
      </div>
      <div className="success-counter-content">
        <div className="success-counter-stat">
          <Typography variant="h3" sx={{ color: "white" }}>
            <Counter minimum={0} maximum={42} time={100} />
          </Typography>
          <Typography
            style={styles}
            letterSpacing={1}
            variant="body1"
            sx={{ color: "white" }}
          >
            {t("expText")}
          </Typography>
        </div>
        <div className="success-counter-stat">
          <Typography variant="h3" sx={{ color: "white" }}>
            <Counter>
              <AllInclusiveIcon sx={{ fontSize: "3.5rem" }} />
            </Counter>
          </Typography>
          <Typography
            style={styles}
            letterSpacing={1}
            variant="body1"
            sx={{ color: "white" }}
          >
            {t("countriesText")}
          </Typography>
        </div>
        <div className="success-counter-stat">
          <Typography variant="h3" sx={{ color: "white" }}>
            {/* +120 */}
            <Counter minimum={1} maximum={120} time={50} />
          </Typography>
          <Typography
            style={styles}
            letterSpacing={1}
            variant="body1"
            sx={{ color: "white" }}
          >
            {t("seafarersText")}
          </Typography>
        </div>
        <div className="success-counter-stat">
          <Typography variant="h3" sx={{ color: "white" }}>
            {/* +5 */}
            <Counter minimum={0} maximum={5} time={1000} />
          </Typography>
          <Typography
            style={styles}
            letterSpacing={1}
            variant="body1"
            sx={{ color: "white" }}
          >
            {t("vesselsText")}
          </Typography>
        </div>
        <div className="success-counter-stat">
          <Typography variant="h3" sx={{ color: "white" }}>
            <Counter minimum={0} maximum={6} time={1000} />
          </Typography>
          <Typography
            style={styles}
            letterSpacing={1}
            variant="body1"
            sx={{ color: "white" }}
          >
            {t("globalOfficesText")}
          </Typography>
        </div>
      </div>

      {/* end of success counter */}

      {/* scroll to top section */}

      <div className="scroll-top">
        <Typography sx={{ color: "black", maxWidth: "60%" }} variant="h6">
          {t("beforeScorllText")}
        </Typography>
        <a href="#firstSection" className="back-to-top">
          <KeyboardDoubleArrowUpIcon sx={{ fontSize: 50 }} />
        </a>
      </div>
    </div>
  );
};

export default Home;
