import React, { useEffect, useState } from "react";
import "./style.css";
const Counter = (props) => {
  const [counter, setCounter] = useState(props?.minimum);

  useEffect(() => {
    const increaseCounter = () => {
      const interval = setInterval(() => {
        setCounter((prevCounter) => {
          const nextCounter = prevCounter + 1;
          if (nextCounter > props.maximum) {
            clearInterval(interval);
            return prevCounter;
          }
          return nextCounter;
        });
      }, props.time);

      return () => {
        clearInterval(interval);
      };
    };

    increaseCounter();
  }, [props?.maximum]);

  return (
    <div className="pulse">{counter ? `+${counter}` : props.children}</div>
  );
};

export default Counter;
